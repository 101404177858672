const Login = () => import('@/views/LoginView.vue');
const ForgotPassword = () => import('@/views/ForgotPassword.vue');
const PasswordReset = () => import('@/views/PasswordReset.vue');
const Sensors = () => import('@/views/SensorsView.vue');
const Control = () => import('@/views/ControlView.vue');
const Absence = () => import('@/views/AbsenceView.vue');
const Building = () => import('@/views/BuildingView.vue');
const Users = () => import('@/views/UsersView.vue');

export default [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/control',
    name: 'control',
    meta: {
      requiresAuth: true,
    },
    component: Control,
  },
  {
    path: '/absences',
    name: 'absences',
    meta: {
      requiresAuth: true,
    },
    component: Absence,
  },
  {
    path: '/sensors',
    name: 'sensors',
    meta: {
      requiresAuth: true,
    },
    component: Sensors,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
  },
  {
    path: '/change-building',
    name: 'building',
    component: Building,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      requiresAuth: true,
    },
    component: Users,
  },
];
